<router-outlet></router-outlet>

<core-loader></core-loader>

<div class="mobile-app-banner-wrapper" *ngIf="showMobileAppBanner"></div>

<div class="mobile-app-banner" *ngIf="showMobileAppBanner">

	<img class="icon" src="./assets/img/smile.svg">

	<div class="title">{{ 'general.title_3' | translate }}</div>

	<div class="title">{{ 'general.title_2' | translate }}</div>

	<div class="subtitle">{{ 'general.subtitle_2' | translate }}:</div>

	<img src="./assets/img/googleplay.svg" (click)="onNavigate('googleplay')"/>
	<img src="./assets/img/appstore.svg" (click)="onNavigate('appstore')"/>

	<div class="lang-toggler">
		<shared-lang-toggler></shared-lang-toggler>
	</div>

</div>
