import {ModuleWithProviders, NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {TranslateModule} from '@ngx-translate/core'
import {ModalModule} from 'ngx-bootstrap/modal'
import {NgxsModule} from '@ngxs/store'
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin'
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin'
import {NgxsRouterPluginModule} from '@ngxs/router-plugin'

import {LoaderComponent} from '@web/core/components/loader/loader.component'
import {DialogComponent} from '@web/core/components/dialog/dialog.component'
import {AppState} from '@web/core/states/app/app.state'
import {AuthState} from '@web/core/states/auth/auth.state'
import {LoaderState} from '@web/core/states/loader/loader.state'
import {LoaderInterceptor} from '@web/core/interceptors/loader.intercepter'
import {RequestInterceptor} from '@web/core/interceptors/request.interceptor'
import {AuthGuard} from '@web/core/guards/auth.guard'
import {RedirectGuard} from '@web/core/guards/redirect.guard'
import {BreadcrumbsState} from '@web/core/states/breadcrumbs/breadcrumbs.state'
import {UserState} from '@web/core/states/user/user.state'
import {SearchState} from '@web/core/states/search/search.state'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {DialogConfirmComponent} from './components/dialog-confirm/dialog-confirm.component'

@NgModule({
  declarations: [
    LoaderComponent,
    DialogComponent,
    DialogConfirmComponent,
  ],
  exports: [
    LoaderComponent,
    DialogComponent,
    DialogConfirmComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    ModalModule.forRoot(),
    NgxsStoragePluginModule.forRoot({key: ['app', 'auth', 'search']}),
    NgxsModule.forRoot([AppState, AuthState, LoaderState, BreadcrumbsState, UserState, SearchState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true,
        },
        AuthGuard,
        RedirectGuard,
      ],
    }
  }
}
