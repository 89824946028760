import {Injectable} from '@angular/core'
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Observable, Subject, throwError} from 'rxjs'
import {catchError, map, switchMap, tap} from 'rxjs/operators'
import {Store} from '@ngxs/store'
import {Navigate} from '@ngxs/router-plugin'
import moment from 'moment'

import {AuthState} from '@web/core/states/auth/auth.state'
import {RemoveToken, UpdateToken} from '@web/core/states/auth/actions'
import {IdentityService} from '@web/core/services/api/identity.service'
import {DialogService} from '@web/core/services/ui/dialog.service'
import {AppState} from '@web/core/states/app/app.state'

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false

  tokenRefreshedSource = new Subject<void>()
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable()

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private identityService: IdentityService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = this.addAuthHeader(request)

    if (this.isTokenExpired(request)) {
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.addAuthHeader(request)
          return next.handle(request)
        }),
        catchError(error => {
          if (error.status !== 401) {
            return this.handleResponseError(error, request, next)
          }

          this.store.dispatch([RemoveToken, new Navigate(['/auth'])])
          return throwError(error)
        }))
    }

    return next.handle(request).pipe(
      catchError(error => {
        return this.handleResponseError(error, request, next)
      }),
    )
  }

  addAuthHeader(request: HttpRequest<any>) {
    if (request.params.has('media_head_req')) {
      return request
    }
    if (request.url.includes('joo.bitrix24.kz')) {
      return request
    }

    request = request.clone({
      setHeaders: {'Accept-Language': this.store.selectSnapshot(AppState.lang)},
    })

    const token = this.store.selectSnapshot(AuthState.token)
    if (token) {
      return request.clone({
        setHeaders: {'Authorization': `Bearer ${token.access}`},
      })
    }

    return request
  }

  isTokenExpired(request: HttpRequest<any>): boolean {
    if (request.params.has('skip_error_handling')) {
      return false
    }

    const {token, accessTokenExpireDate} = this.store.selectSnapshot(AuthState)
    if (!token || !accessTokenExpireDate) {
      return false
    }

    const leftSeconds = moment().diff(accessTokenExpireDate, 'seconds')

    return leftSeconds > -30
  }

  handleResponseError(error, request?, next?) {
    if (request.params.has('skip_error_handling')) {
      return throwError(error)
    }

    if (error.status === 401 && !request.params.has('skip_token_refresh_handling')) {
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.addAuthHeader(request)
          return next.handle(request)
        }),
        catchError(e => {
          if (e.status !== 401) {
            return this.handleResponseError(error, request, next)
          }

          this.store.dispatch([RemoveToken, new Navigate(['/auth'])])
          return throwError(e)
        }))
    }

    let message = ''

    if (error.status >= 500) {
      message = 'errors.server_error'
    } else if (error.status === 404) {
      message = 'errors.not_found'
    } else {
      message = this.extractHttpErrorMessage(error.error)
    }

    this.dialogService.error({message})

    return throwError(error)
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next()
          observer.complete()
        })
      })
    } else {
      this.refreshTokenInProgress = true
      const token = this.store.selectSnapshot(AuthState.token)

      return this.identityService.refreshToken(token.refresh).pipe(
        map(token => {
          this.store.dispatch(new UpdateToken(token))
          return token
        }),
        tap(() => {
          this.refreshTokenInProgress = false
          this.tokenRefreshedSource.next()
        }),
        catchError(error => {
          this.refreshTokenInProgress = false
          return throwError(error)
        }))
    }
  }

  private extractHttpErrorMessage(data: any) {
    if (!data || typeof data === 'string') {
      return data
    }
    if (Array.isArray(data)) {
      return this.extractHttpErrorMessage(data[0])
    } else {
      const objKeys = Object.keys(data)
      return this.extractHttpErrorMessage(data[objKeys[0]])
    }
  };
}
