import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {Store} from '@ngxs/store'

import {AuthState} from '@web/core/states/auth/auth.state'
import {UpdateStatic} from '@web/core/states/app/actions'

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.store.selectSnapshot(AuthState.token)) {
      this.store.dispatch(UpdateStatic)
      console.info('[AuthGuard] Has token, entering app.')
      return true
    }

    console.info('[AuthGuard] No token, redirecting auth.')
    this.router.navigate(['/auth'], {queryParams: {redirectUrl: state.url}})
    return false
  }
}
