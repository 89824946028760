import {Injectable} from '@angular/core'
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Store} from '@ngxs/store'
import {Observable} from 'rxjs'
import {finalize} from 'rxjs/operators'

import {SKIP_LOADER_SHOWING_PARAM_KEY} from '@web/core/utils/params'
import {PopLoaderQueue, PushLoaderQueue} from '@web/core/states/loader/actions'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private store: Store,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.params.has(SKIP_LOADER_SHOWING_PARAM_KEY) || req.url.includes(SKIP_LOADER_SHOWING_PARAM_KEY)) {
      return next.handle(
        req.clone({
          params: req.params.delete(SKIP_LOADER_SHOWING_PARAM_KEY),
        }),
      )
    }

    this.store.dispatch(new PushLoaderQueue(req.url))

    return next.handle(req)
      .pipe(
        finalize(() => {
          this.store.dispatch(new PopLoaderQueue(req.url))
        }),
      )
  }
}
